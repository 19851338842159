import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Footer from '@components/Footer'
import ImageContent from '@components/ImageContent'
import RichFeaturedLinks from '@components/RichFeaturedLinks'
import Spacer from '@components/Spacer'
import Hero from '@components/Home/Hero'
import List from '@components/Home/LatestList'
// import HomeCountdown2 from '@components/HomeCountdown2'

const IndexPage = ({ data, location }) => {
  const {
    seo,
    heroCarouselItems,
    // countdownHeading,
    richFeaturedResources,
    meetVickiBlock,
  } = data.contentfulHome

  // const { stickyBannerText, countdownTitles } = data.contentfulGlobals

  return (
    <>
      <Seo data={seo} slug={''} />
      <Hero slides={heroCarouselItems} />
      {/* <Spacer size={[70, 75]} />
      <HomeCountdown2
        heading={countdownHeading || stickyBannerText}
        weekends={countdownTitles}
      /> */}
      <Spacer size={[70, 95]} />
      <RichFeaturedLinks
        heading={'Browse our resources'}
        blocks={richFeaturedResources}
      />
      <List
        heading="Latest News"
        subtitle="Here's a round up of our latest news updates in one place."
        articles={data.allContentfulNewsArticle.nodes}
        linkLabel="Full Article"
        archiveLink="/news"
        archiveLinkLabel="View All News"
      />
      <ImageContent
        image={meetVickiBlock.imageLandscape}
        imageLabel={meetVickiBlock.imageLabel}
        content={meetVickiBlock.content}
        flip={meetVickiBlock.flip}
        headingSize={'small'}
        wide={true}
      />
      <Footer location={location} />
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomePage {
    contentfulHome {
      seo {
        ...SEO
      }
      heroCarouselItems {
        label
        title
        content
        link {
          ...Link
        }
        smallImage {
          gatsbyImageData(aspectRatio: 1.6)
          description
        }
        largeImage {
          gatsbyImageData(width: 1720, height: 1720)
          description
        }
      }
      countdownHeading
      richFeaturedResources {
        ...ImageContentBlock
      }
      meetVickiBlock {
        ...ImageContentBlock
      }
    }
    contentfulGlobals {
      stickyBannerText
      countdownTitles {
        ...TitleContentBlock
      }
    }
    allContentfulNewsArticle(limit: 3) {
      nodes {
        internal {
          type
        }
        title
        slug
        excerpt
        introImage {
          gatsbyImageData(aspectRatio: 1.6)
          description
        }
      }
    }
  }
`
