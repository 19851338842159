import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import { ImageContentWrapper, ImageWrapper } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RichText from '@components/RichText'
import { GatsbyImage } from 'gatsby-plugin-image'
import RoundedImage from '@components/RoundedImage'
import { Label } from '@components/TextStyles'
import { colors } from '@styles/vars/colors.style'
import AnimateImage from '@components/animation/AnimateImage'

const ImageContent = ({
  id,
  image,
  imageLabel,
  content,
  flip,
  intro,
  paragraphSize,
  headingSize,
  wide,
  backgroundColor,
  buttonSize,
}) => {
  return (
    <ImageContentWrapper
      id={id}
      roundedTop={intro}
      backgroundColor={backgroundColor}
    >
      <Container>
        <Grid>
          <GridItem
            desk={wide ? 6 : 5}
            deskOrder={flip ? 2 : 1}
            deskStart={flip ? (wide ? 7 : 8) : 1}
            deskAlign={`center`}
          >
            <ImageWrapper label={imageLabel}>
              {imageLabel && (
                <Label color={colors.light} dot>
                  {imageLabel}
                </Label>
              )}
              <RoundedImage>
                <AnimateImage delay={0} hover={false}>
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.description}
                  />
                </AnimateImage>
              </RoundedImage>
            </ImageWrapper>
          </GridItem>
          <GridItem
            desk={5}
            deskOrder={flip ? 1 : 2}
            deskStart={flip ? (wide ? 1 : 2) : wide ? 8 : 7}
            deskAlign={`center`}
          >
            <RichText
              content={content}
              paragraphSize={paragraphSize}
              headingSize={headingSize}
              buttonSize={buttonSize}
            />
          </GridItem>
        </Grid>
      </Container>
    </ImageContentWrapper>
  )
}

ImageContent.propTypes = {
  id: PropTypes.string,
  image: PropTypes.object,
  imageLabel: PropTypes.string,
  content: PropTypes.object,
  flip: PropTypes.bool,
  intro: PropTypes.bool,
  paragraphSize: PropTypes.oneOf(['regular', 'small']),
  headingSize: PropTypes.oneOf(['regular', 'small']),
  wide: PropTypes.bool,
  backgroundColor: PropTypes.string,
  buttonSize: PropTypes.oneOf(['large', 'small']),
}

ImageContent.defaultProps = {
  paragraphSize: 'regular',
  headingSize: 'regular',
  buttonSize: 'large',
}

export default ImageContent
